import { PropsWithChildren } from 'react';
import { useAuth } from '@/features/auth/store/authStore';
import Logo from '@repo/assets/brand/images/logo';
import LogoSmall from '@repo/assets/brand/images/logo-small';
import {
  ExternalLink,
  FolderClosed,
  HelpCircle,
  Home,
  LucideIcon,
  Menu,
  PercentSquare,
  PersonStanding,
  Settings,
  UserRoundPlus,
} from '@repo/assets/shared';
import { Badge } from '@repo/ui/components/ui/badge';
import { Button } from '@repo/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@repo/ui/components/ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from '@repo/ui/components/ui/sheet';
import { cn } from '@repo/ui/lib/utils';
import { getCurrentYear } from '@repo/utils';
import { Link, LinkProps, useNavigate } from '@tanstack/react-router';

type NavigationRoute = {
  name: string;
  href?: string;
  Icon: LucideIcon;
  badgeValue?: number | null;
  show?: boolean;
} & LinkProps;

type NavigationRoutes = Array<NavigationRoute>;

type AuthenticatedAppShellProps = PropsWithChildren<{
  className?: string;
  numberOfDocuments?: number | null;
  routesToShow: Record<string, boolean | undefined>;
}>;

function getTopLevelNavigationRoutes({
  numberOfDocuments,
  routesToShow,
}: Pick<AuthenticatedAppShellProps, 'numberOfDocuments' | 'routesToShow'>) {
  return [
    {
      to: '/dashboard',
      name: 'Dashboard',
      activeOptions: {
        exact: true,
      },
      show: routesToShow['/dashboard'],
      Icon: Home,
    },
    {
      to: '/documents',
      name: 'Documents',
      Icon: FolderClosed,
      show: routesToShow['/documents'],
      badgeValue: numberOfDocuments,
    },
    {
      to: '/referrals',
      name: 'Refer a Friend',
      Icon: UserRoundPlus,
      show: routesToShow['/referrals'],
    },
    {
      to: '/account-settings',
      name: 'Settings',
      Icon: Settings,
      show: routesToShow['/account-settings'],
    },
    {
      to: '/investor-type',
      name: 'Investor Type',
      Icon: PersonStanding,
      show: routesToShow['/investor-type'],
    },
    {
      to: '/investor-hub',
      name: 'Investor Hub',
      Icon: PercentSquare,
      show: routesToShow['/investor-hub'],
    },
    {
      href: 'mailto:info@pfida.com',
      name: 'Help',
      Icon: HelpCircle,
      show: routesToShow.help,
    },
  ] satisfies NavigationRoutes;
}

function DesktopNavigation({ numberOfDocuments, routesToShow }: AuthenticatedAppShellProps) {
  const navigationRoutes = getTopLevelNavigationRoutes({ numberOfDocuments, routesToShow });
  return (
    <aside className="bg-primary sticky top-0 hidden min-h-dvh self-start md:block">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="md:px-4 flex items-center justify-center px-6 py-4">
          <Link to="/" className="flex items-center gap-2 font-semibold">
            <img src={String(Logo)} alt="Pfida Logo" />
          </Link>
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            {navigationRoutes.map((props) => {
              if (props.href) {
                return (
                  <a
                    key={props.name}
                    href={props.href}
                    className="text-primary-foreground flex items-center gap-3 rounded bg-transparent px-3 py-2 transition-all hover:translate-x-1"
                  >
                    {props.Icon && <props.Icon className="h-4 w-4" />}
                    {props.name}
                  </a>
                );
              }
              return (
                <Link
                  key={props.name}
                  params={{}}
                  to={props.to!}
                  {...props}
                  className={cn(
                    'text-primary-foreground data-[status=active]:bg-muted/5 flex items-center gap-3 rounded bg-transparent px-3 py-2 transition-all hover:translate-x-1',
                    props.show ? '' : 'hidden'
                  )}
                >
                  {props.Icon && <props.Icon className="h-4 w-4" />}
                  {props.name}
                  {props.badgeValue ? (
                    <Badge
                      variant="destructive"
                      className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-md"
                    >
                      {props.badgeValue}
                    </Badge>
                  ) : null}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </aside>
  );
}

function UserMenu() {
  const navigate = useNavigate();
  const signOut = useAuth.use.signOut();
  const user = useAuth.use.user();

  function handleLogout() {
    signOut();
    navigate({
      to: '/login',
      search: {
        to: '/dashboard',
      },
    });
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="capitalize">
          {user?.name ?? 'Unknown'}
          <span className="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem>
          <button type="button" onClick={() => handleLogout()}>
            Logout
          </button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function MobileNavigation({
  numberOfDocuments,
  routesToShow,
}: Pick<AuthenticatedAppShellProps, 'routesToShow' | 'numberOfDocuments'>) {
  const navigate = useNavigate();
  const signOut = useAuth.use.signOut();

  const navigationRoutes = getTopLevelNavigationRoutes({ numberOfDocuments, routesToShow });
  function handleLogout() {
    signOut();
    navigate({
      to: '/login',
      search: {
        to: '/dashboard',
      },
    });
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="shrink-0 md:hidden">
          <Menu className="h-6 w-6" />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="flex flex-col">
        <nav className="grid gap-2 text-lg font-medium">
          <Link to="/" className="mb-4 flex items-center gap-2 text-lg font-semibold">
            <img src={String(Logo)} alt="Pfida Logo" className="h-6 w-6" />
            <span className="sr-only">Pfida</span>
          </Link>
          {navigationRoutes.map((props) => {
            if (props.href) {
              return (
                <a
                  key={props.name}
                  href={props.href}
                  className="text-foreground hover:bg-primary/5  mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2"
                >
                  {props.Icon && <props.Icon className="h-4 w-4" />}
                  {props.name}
                </a>
              );
            }
            return (
              <Link
                key={props.name}
                params={{}}
                to={props.to!}
                {...props}
                className={cn(
                  'text-foreground hover:bg-primary/5 data-[status=active]:bg-primary data-[status=active]:text-primary-foreground mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2',
                  props.show ? '' : 'hidden'
                )}
              >
                {props.Icon && <props.Icon className="h-5 w-5" />}
                {props.name}
                {props.badgeValue ? (
                  <Badge
                    variant="destructive"
                    className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-md"
                  >
                    {props.badgeValue}
                  </Badge>
                ) : null}
              </Link>
            );
          })}
        </nav>
        <Button className="mt-auto" onClick={() => handleLogout()}>
          Logout
        </Button>
      </SheetContent>
    </Sheet>
  );
}

function Footer({ className }: { className?: string }) {
  const currentYear = getCurrentYear();
  return (
    <footer className={cn('px-4 pb-4 lg:px-6', className)}>
      <p>
        COPYRIGHT ©{currentYear}{' '}
        <a href="https://pfida.com" className="text-primary inline-flex items-baseline justify-center">
          Pfida
          <ExternalLink className="ml-1 inline h-4 w-4" />
        </a>
        . All rights reserved.{' '}
        <a
          href="https://www.pfida.com/privacy-policy/"
          className="text-primary inline-flex items-baseline justify-center"
        >
          Privacy Policy
          <ExternalLink className="ml-1 inline h-4 w-4" />
        </a>
      </p>
    </footer>
  );
}

function Header({
  numberOfDocuments,
  routesToShow,
}: Pick<AuthenticatedAppShellProps, 'routesToShow' | 'numberOfDocuments'>) {
  return (
    <header className="bg-background/95 sticky top-0 z-50 flex h-20 items-center px-6">
      <div className=" bg-card mt-8 flex w-full flex-1 gap-2 rounded-md px-4 py-4 shadow-sm">
        <Link to="/" className="flex items-center gap-2 font-semibold">
          <img src={String(LogoSmall)} alt="Pfida Logo" className="h-7" />
        </Link>

        <div className="ml-auto flex items-center justify-center">
          <UserMenu />
          <MobileNavigation numberOfDocuments={numberOfDocuments} routesToShow={routesToShow} />
        </div>
      </div>
    </header>
  );
}

export function AuthenticatedAppShell({
  children,
  className,
  numberOfDocuments,
  routesToShow,
}: AuthenticatedAppShellProps) {
  return (
    <div className={cn('grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[260px_1fr]', className)}>
      <DesktopNavigation numberOfDocuments={numberOfDocuments} routesToShow={routesToShow} />
      <div className="flex flex-col">
        <Header numberOfDocuments={numberOfDocuments} routesToShow={routesToShow} />
        <main className="flex flex-1 flex-col gap-4 p-6 lg:gap-6 lg:p-8">{children}</main>
        <Footer />
      </div>
    </div>
  );
}
