import { PropsWithChildren } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { TanstackQueryProvider } from '../libs/tanstackQuery';

export function AppProviders({ children }: PropsWithChildren) {
  return (
    <HelmetProvider>
      <TanstackQueryProvider>{children}</TanstackQueryProvider>
    </HelmetProvider>
  );
}
