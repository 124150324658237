import { GeneralErrorBoundary } from '@/components/errorBoundaries/generalErrorBoundary';
import { MifeParcel } from '@/components/MifeParcel';
import { getReferralsSuspenseQueryOptions } from '@repo/api/services/userBackend/gen';
import { isTenant } from '@repo/utils';
import { createFileRoute, redirect } from '@tanstack/react-router';

export function canAccessReferrals(showReferrals: boolean | undefined) {
  return isTenant('pfida') && showReferrals;
}

export const Route = createFileRoute('/_authenticated/_pfida/referrals')({
  loader: async ({ context: { queryClient } }) => {
    const data = await queryClient.ensureQueryData(getReferralsSuspenseQueryOptions());

    if (!canAccessReferrals(data.data.show_referrals)) {
      redirect({
        to: '/dashboard',
        throw: true,
      });
    }
  },
  component: Referrals,
  errorComponent: GeneralErrorBoundary,
});

function Referrals() {
  return <MifeParcel />;
}
