import { GeneralErrorBoundary } from '@/components/errorBoundaries/generalErrorBoundary';
import { MifeParcel } from '@/components/MifeParcel';
import { Pfidac } from '@repo/api/services/userBackend/gen';
import { isTenant } from '@repo/utils';
import { createFileRoute } from '@tanstack/react-router';

export function canAccessInvestorHub(pfidac: Pfidac) {
  return isTenant('pfida') && pfidac.show_equity_hub;
}

export const Route = createFileRoute('/_authenticated/_pfida/investor-hub')({
  component: () => <MifeParcel />,
  errorComponent: GeneralErrorBoundary,
});
