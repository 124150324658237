import { useEffect } from 'react';
import { Button } from '@repo/ui/components/ui/button';
import { getErrorMessage, HttpError, isHttpError, StatusCodes } from '@repo/utils';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';

type StatusHandler = (info: { error: HttpError }) => JSX.Element | null;

type GeneralErrorBoundaryProps = {
  error: unknown;
  reset: () => void;
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Partial<Record<StatusCodes, StatusHandler>>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
};

export function GeneralErrorBoundary({
  error,
  reset,
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.code} {error.message}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: GeneralErrorBoundaryProps) {
  const router = useRouter();

  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  function handleRetry() {
    reset();
    router.invalidate();
  }

  return (
    // TODO: Add styles
    <div className="bg-destructive text-h2 text-destructive-foreground container mx-auto flex h-full w-full items-center justify-center p-20 flex-col gap-1">
      {isHttpError(error)
        ? (statusHandlers?.[error.code] ?? defaultStatusHandler)({
            error,
          })
        : unexpectedErrorHandler(error)}
      <Button onClick={() => handleRetry()}>Retry</Button>
    </div>
  );
}
