import React from 'react';
import favicon from '@repo/assets/brand/images/favicon';
import { ClientEnv, clientEnv } from '@repo/utils';
import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { Helmet } from 'react-helmet-async';

const TanStackRouterDevtools =
  clientEnv.VITE_APP_MODE === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

const title: Record<ClientEnv['VITE_TENANT_ID'], string> = {
  'pfida-5a34D': 'Pfida - App Dashboard',
  'waafi-0hjE2': 'Waafi - App Dashboard',
};

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()({
  component: () => (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={String(favicon)} sizes="16x16" />
        <title>{title[clientEnv.VITE_TENANT_ID]}</title>
      </Helmet>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
});
