import { GeneralErrorBoundary } from '@/components/errorBoundaries/generalErrorBoundary';
import { MifeParcel } from '@/components/MifeParcel';
import { canAccessCalculators } from '@/features/calculators/utils/canAccessCalculators';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_pfida/calculators')({
  beforeLoad: () => {
    if (!canAccessCalculators()) {
      redirect({
        to: '/dashboard',
        throw: true,
      });
    }
  },
  component: () => <MifeParcel />,
  errorComponent: GeneralErrorBoundary,
});
