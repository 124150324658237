import { GeneralErrorBoundary } from '@/components/errorBoundaries/generalErrorBoundary';
import { MifeParcel } from '@/components/MifeParcel';
import { createFileRoute } from '@tanstack/react-router';

function Dashboard() {
  return <MifeParcel />;
}

export const Route = createFileRoute('/_authenticated/dashboard')({
  component: Dashboard,
  errorComponent: GeneralErrorBoundary,
});
